<template>
  <div>
    <div class="w3-container w3-margin-bottom zero">
      <loading v-bind:dspin="!contentLoaded"></loading>
    </div>

    <div class="flow-card blue-top w3-padding qpp-margin-sides-5pc w-85pc" v-show="contentLoaded">
      <table  class="w3-table w3-bordered flow-table">
        <thead class="">
          <th>{{ $t("name") }}</th>
          <th>{{ $t("organization") }}</th>
          <th>{{ $t("type") }}</th>
          <th>{{ $t("domain-message") }}</th>
        </thead>

        <tbody v-show="domains.length === 0">
        <tr>
          <td class="w3-center flow-blue-color" colspan="8">
            <b class="font-size-1pt25em">{{ $t("noDomains") }}</b>
            {{ domains }}
          </td>
        </tr>
        </tbody>

        <tbody v-show="domains.length !== 0" class="cursor-pointer">
        <tr v-for="domain in domains"
            v-bind:id="domain.id" :key="domain.id"
            @click="showEditForm(domain)">
          <td>
            <span>  {{ domain.name }}</span>
          </td>
          <td>
            <span> {{ domain.type.organization.name }} </span>
          </td>
          <td>
            <span> {{ domain.type.name }} </span>
          </td>
          <td>
            <span class="w3-tooltip">
            <span> {{ truncate(domain.notificationTextValue) }} </span>
            <span class="w3-text w3-tag standard-text-hover"
                  v-if="domain.notificationTextValue != null && domain.notificationTextValue.length>=messageCutOff">
                {{domain.notificationTextValue}} </span>
            </span>
          </td>

        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Loading from "../../common/Loading";

export default {
  name: "DomainsList",
  props: ['newDomainAdded','standardTextDictionary'],
  emits: ['openDomainDetails'],
  components: {Loading},

  data() {
    return {
      contentLoaded: false,
      domains: [],
      isAdmin: true,
      messageCutOff:50
    }
  },

  watch: {
    newDomainAdded: function() {
      this.getAllDomains();
    }
  },

  mounted() {
    this.getAllDomains();
  },

  methods: {
    truncate(message){
      const app = this;
      if(message) {
        if (message.length > app.messageCutOff)
          return message.substring(0, app.messageCutOff-5) + "(...)";
        else {
          return message;
        }
      } else return "";
    },
    getAllDomains() {
      const app = this;
      app.domains = [];
      app.$restClient.domains.all().then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          let domains = JSON.parse(JSON.stringify(data));
          domains.forEach(domain => {
            domain.notificationTextValue = app.standardTextDictionary[domain.notificationTextKey];
          })
          app.domains = domains;

        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    showEditForm(domain) {
      this.$emit('openDomainDetails', domain);
    }
  }
}
</script>