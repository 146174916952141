<template>
  <div>
    <div class="w3-container w3-margin-bottom zero">
    </div>

    <div class="flow-margin-top flow-card blue-top w3-padding qpp-margin-sides-5pc w-85pc">
      <table class="w3-table flow-table">
        <thead class="">
        <th>{{ $t("parameter") }}</th>
        <th>{{ $t("description") }}</th>
        <th>
          <div class="w3-right w3-margin-right flow-margin-top">
            <i class="fa fa-expand-alt flow-blue-color w3-xlarge cursor-pointer w3-margin-right"
               v-if="expandPlaceholders === true"
               @click="expandPlaceholders = false"></i>
            <i class="fa fa-compress-alt flow-blue-color w3-xlarge cursor-pointer w3-margin-right"
               v-if="expandPlaceholders === false"
               @click="expandPlaceholders = true"></i>
          </div>
        </th>
        </thead>

        <tbody v-show="placeholders.length === 0">
        <tr>
          <td class="w3-center flow-blue-color" colspan="8">
            <b class="font-size-1pt25em">{{ $t("noPlaceholders") }}</b>
            {{ placeholders }}
          </td>
        </tr>
        </tbody>

        <tbody v-show="placeholders.length !== 0" class="cursor-pointer" v-if="expandPlaceholders === true">
        <tr v-for="(ph, index) in placeholders"
            v-bind:id="ph.key" :key="ph.key" >
          <td>
            <span>  {{ ph.name }}</span>
          </td>
          <td>
            <span> {{ ph.value }} </span>
          </td>
          <td v-if="index === 0" class="font-weight-bold" rowspan="2">
            <div>{{ $t("placeholders-message-heading") }}</div>
            <div>{{ $t("placeholders-message-body1") }}</div>
            <div>{{ $t("placeholders-message-body2") }}</div>
          </td>

        </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>
<script>

export default {
  name: 'PlaceholderList',
  props: ['placeholders'],
  data() {
    return{
      expandPlaceholders: false
    }
  },
  watch: {
    placeholders: function () {
      if (this.placeholders.length > 0) this.contentLoaded = true;
    }
  },
  methods:{
    open(){
      this.expandPlaceholders = true;
    },

    close(){
      this.expandPlaceholders = false;
    }
  }


}
</script>