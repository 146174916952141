const Helper = {
    sortByKey(array, key) {
        if (array && array.length > 1) {
            array.sort(function (a, b) {
                return a[key].localeCompare(b[key]);
            });
        }
    },
    getTime(_date) {
        let date = new Date(_date);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes;
    },
    getDate(dStr, format) {
        var now = new Date();
        if (format === "h:m") {
            now.setHours(dStr.substr(0, dStr.indexOf(":")));
            now.setMinutes(dStr.substr(dStr.indexOf(":") + 1));

            var yyyy = now.getFullYear();
            var MM = now.getMonth() + 1;
            var dd = now.getDate();
            var hh = now.getHours();
            var mm = now.getMinutes();
            var d = yyyy + '-' + (MM < 10 ? '0' + MM : MM) + '-' + (dd < 10 ? '0' + dd : dd) + ' ' + (hh < 10 ? '0' + hh : hh) + ':' + (mm < 10 ? '0' + mm : mm) + ":00";
            return d;
        } else
            return "Invalid Format";
    },
    getDateAsYYYYMMDD(date) {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return yyyy + '-' + mm + '-' + dd;
    },
    validateUrl(url){
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(url);
    },
    validatePhone(phone){
        const pattern = new RegExp('^\\+?([0-9]{2})\\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$','i'); // fragment locator
        const pattern1 = new RegExp('\\+324[0-9]{8}','g'); // fragment locator
        return !!pattern.test(phone) || !!pattern1.test(phone);
    },
    NumberWithLeadingZeros(number,desiredLength){
        let numAsString = number.toString();
        while (numAsString.length < desiredLength) numAsString = "0" + numAsString;
        return numAsString;
    }
};

export default {
    install: (app) => {
        app.config.globalProperties.$helper = Helper
    }
}