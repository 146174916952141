<template>
  <div class="w3-left-align">
    <i class="fa fa-inbox flow-blue-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('editDomain')"></content-header>
    <div class="w3-margin-top w3-margin-bottom w3-left-align">

      <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">

        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("name") }}</label>
          <input v-model="domain.name" class="w3-input w3-border w3-round-medium"
                 type="text"
                 v-bind:placeholder="$t('name')"/>
          <span class="w3-text-red qpp-field-error-message qpp-field-error-message-domain" v-if="!domain.name">
            {{ $t("INVALID_NAME") }}</span>
        </p>
        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("organization") }}</label>
          <input v-model="organization" class="w3-input w3-border w3-round-medium"
                 disabled="disabled" type="text"
                 v-bind:placeholder="$t('organization')"/>
        </p>



        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("type") }}</label>
          <span class="sort-select w3-col zero flow-margin-right">
                    <flow-select :callback="setSelectedType" :items="types"
                                 :preSelectedItem="domain.type"
                                 :valueKey="'name'"
                                 :valueKeyIndex="'id'"
                                 class="w3-border w3-round-medium"
                                 type="text">
                    </flow-select>
                </span>
        </p>

        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("prefix") }}</label>
          <input v-model="domain.prefix" class="w3-input w3-border w3-round-medium"
                 disabled="disabled" type="text"
                 v-bind:placeholder="$t('prefix')"/>
        </p>

        <div class="w3-row">
          <p class="flow-padding-bottom zero-top w-67pc flow-flex-col w3-left">
            <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("domain-message") }}</label>
            <flow-select :callback="setSelectedNotificationText" :items="standardTexts"
                         :preSelectedItem="domain.notificationText"
                         :valueKey="'value'"
                         :valueKeyIndex="'key'"
                         class="w3-border w3-round-medium"
                         type="text">
            </flow-select>
          </p>

        <p class="flow-padding-bottom zero-top w-30pc flow-flex-col w3-right">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("closing-message-threshold") }}</label>
          <input v-model="domain.closingTimeMinutes" class="w3-input w3-border w3-round-medium"
                 type="number"
                 v-bind:placeholder="$t('minutes')"/>
        </p>



        </div>
        <div v-if="showMinutesError">
          <p><span class="w3-text-red qpp-field-error-message">
            {{ minutesError }}</span>
          </p>
          <br>
          <br>
        </div>
        <div class="w3-row">
        <p class="flow-padding-bottom zero-top w-32pc flow-flex-col w3-left ">
          <label class="w3-left flow-blue-color flow-padding-bottom w-100pc">{{ $t("created-at") }}</label>
          <input v-model="domain.createdAt" class="w3-input w3-border w3-round-medium "
                 disabled="disabled" type="text"
                 v-bind:placeholder="$t('created-at')"/>
        </p>
        <p class="flow-padding-bottom zero-top zero-right w-30pc flow-flex-col w3-left qpp-margin-sides-5pc">
          <label class="w3-left flow-blue-color flow-padding-bottom domain-form-label">
            {{ $t('updated-at-and-by') }}</label>
          <input v-model="domain.updatedAt" class="w3-input w3-border w3-round-medium flow-margin-top-22-px"
                 disabled="disabled" type="text"
                 v-bind:placeholder="$t('updated-at')"/>
        </p>
        <p class="flow-padding-bottom w-30pc flow-flex-col w3-right flow-margin-left flow-margin-top-22-px">
          <input v-model="domain.updatedBy" class="w3-input w3-border w3-round-medium w-100pc"
                 disabled="disabled" type="text"
                 v-bind:placeholder="$t('updated-by')"/>
        </p>
        </div>

      </div>


      <div class="w3-container zero-bottom zero-top">
        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                @click.prevent="updateDomain">
          <i class="fa fa-save flow-green-active-color">
            <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
          </i>
        </button>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top"
                @click.prevent="closeWindow">
          <i class="fa fa-arrow-left w3-text-red">
            <span><b class="flow-margin-left">{{ $t('back') }}</b></span>
          </i>
        </button>
      </div>

    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import FlowSelect from "@/components/common/FlowSelect";

export default {
  name: "EditDomainForm",
  components: {ContentHeader, FlowSelect},
  emits: ['updatedDomain', 'onCloseWindow'],
  props: ['types', 'editDomain','standardTexts'],

  data() {
    return {
      domain: {},
      showMinutesError: false,
      minutesError:""
    }
  },
  mounted() {
    const app = this;
    app.setDomain();

  },
  watch:{
    editDomain() {
      this.setDomain();
    }
  },
  methods: {
    checkClosingTimeMessageAndMinutes(){
      const app = this;
      app.showMinutesError = false;
      app.minutesError = "";
      let minutes = app.domain.closingTimeMinutes;
      let textKey = app.domain.notificationTextKey;

      if (minutes && ((minutes > 180|| minutes < 0))) {
        app.showMinutesError = true;
        app.minutesError = app.$t('INVALID_MINUTES');
      } else if(minutes && minutes>0 && !textKey){
        app.showMinutesError = true;
        app.minutesError = app.$t("INVALID_NOTIFICATION_TEXT");
      } else if (textKey && (!minutes || minutes===0 || minutes==="0")){
        app.showMinutesError = true;
        app.minutesError = app.$t("INVALID_CLOSING_TIME_MINUTES");
      }
    },
    setSelectedNotificationText(item){
      const app = this;
      if(item) {
        app.domain.notificationText = item;
        app.domain.notificationTextKey = item.key;
      }
    },
    setDomain() {
      const app = this;
      let temp = JSON.parse(JSON.stringify(this.editDomain)); // cloning the domain data
      app.domain = {};
      app.domain = JSON.parse(JSON.stringify(temp));
      if(temp&& temp.notificationTextKey){
        let standardText = app.standardTexts.find(item => item.key===temp.notificationTextKey);
        app.domain.notificationText = standardText;
      }


    },

    updateDomain() {
      if (!this.domain.name || !this.domain.name.trim()) {
        return;
      }


      this.checkClosingTimeMessageAndMinutes();
      if(this.showMinutesError){
        return;
      }

      const app = this;
      let userId = app.$store.getters["users/getCurrentUser"].id;
      app.$restClient.domains.update(this.domain,userId).then(response => {
        // eslint-disable-next-line no-unused-vars
        let data = response.data;
        this.$parent.$emit("updatedDomain", data);
        app.closeWindow();
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
      }).catch(err => {
        if(err && err.response.status === 400)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('domain-name-unique')});
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      });

    },

    setSelectedType(option) {
      this.domain.type = option;
    },

    closeWindow() {
      this.$parent.$emit('onCloseWindow');
    }
  },
  computed: {
    organization: {
      get() {
        if (this.domain && this.domain.type) {
          return this.domain.type.organization.name
        } else {
          return ""
        }
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {
        // no need to set organization

      }
    }
  }
}
</script>