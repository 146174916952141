<template>
  <div>
    <div class="w3-col flow-card blue-top">
      <table class="w3-table w3-bordered flow-table visits-table">
        <thead class="">
        <th>{{ $t("arrivalTime") }}</th>
        <th>{{ $t("appointmentTime") }}</th>
        <th>{{ $t("fullName") }}</th>
        <th>{{ $t("domainType") }} - {{ $t("type") }}</th>
        <th v-if="!openCard">{{ $t("contact") }}</th>
        <th v-if="!openCard">{{ $t("location") }}</th>
        <th v-if="!openCard">{{ $t("language") }}</th>
        </thead>

        <tbody v-show="visits.length === 0">
        <tr>
          <td colspan="8" class="w3-center flow-blue-color">
            <b class="font-size-1pt25em">{{ $t("novisits") }}</b>
          </td>
        </tr>
        </tbody>

        <tbody v-show="visits.length !== 0">
        <tr v-for="visit in visits" v-bind:key="visit.id"
            :class="visit.priority && visit.status==='ARRIVED'  ? 'crossed-threshold':''">
          <td>
            {{ visit.arrivalTime }}
          </td>
          <td>
            {{ visit.appointmentTime }}
          </td>

          <td class="primary" @click="visitSelected(visit)">
            <visit-status class="w3-show-inline-block w3-margin-right"
                          :status="visit.status"
                          :showMarker="true"></visit-status>
            <span>{{ visit.firstName }}</span>
            <span>&nbsp;{{ visit.lastName }}</span>
            <span v-if="visit.externalCustomerId && !openCard">&nbsp;- {{ visit.externalCustomerId }}</span>
          </td>

          <td>
            <span> {{ visit.domainTypeName }}</span>
            <span v-if="visit.appointmentType"> - {{ visit.appointmentType }}</span>
          </td>

          <td v-if="!openCard">
            {{ visit.visitContactUser }}
          </td>

          <td v-if="!openCard">
            {{ visit.waitingRoomName }}
          </td>

          <td v-if="!openCard">
            {{ visit.languageCode }}
          </td>

          <td v-if="!ongoing">
            <i v-if="visit.status==='ARRIVED'" class="fa fa-bullhorn w3-text-deep-orange cursor-pointer"
               :title="$t('call')"
               @click="callVisitor(visit.visitId)"></i>
          </td>

        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import VisitStatus from "../../common/VisitStatus";

export default {
  name: "visitsForCaller",
  components: {VisitStatus},
  props: ['visits', 'onSelectCallback', 'openCard', 'callVisitor', "ongoing"],
  data: function () {
    return {}
  },
  mounted() {
  },
  watch: {
    visits() {
    },
    openCard() {
    }
  },
  methods: {
    visitSelected(visit) {
      this.onSelectCallback(visit.visitId, visit.visitorId);
    }
  }

}
</script>

<style scoped>


</style>