class Desks {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_VISITS: '/api/sites/:siteId/visits/desks/:deskId/users/:userId',
            GET_ONGOING_VISIT: '/api/sites/:siteId/visits/desks/:deskId/users/:userId/current',
            END_ONGOING_VISIT: '/api/sites/:siteId/visits/desks/:deskId/users/:userId/end/:visitId',
            END_ONGOING_GROUP_VISIT: '/api/sites/:siteId/visits/desks/:deskId/users/:userId/end',
            CANCEL_VISIT: '/api/sites/:siteId/visits/desks/:deskId/users/:userId/cancel/:visitId',
            CALL_VISITOR: '/api/sites/:siteId/visits/desks/:deskId/users/:userId/call/:visitId',
            GET_CURRENT_VISITOR_DETAILS: '/api/sites/:siteId/visitors/:visitorId/desks/:deskId/users/:userId/visits/:visitId',
            UPDATE_VISIT_DETAILS: '/api/sites/:siteId/visits/desks/:deskId/users/:userId/visit/update',
            ADD_VISIT_PERSON: '/api/sites/:siteId/visits/desks/:deskId/users/:userId/add'
        };
    }

    all(params) {
        let url = this.EP.GET_ALL_VISITS;
        if (params.query !== '') {
            url = url + '?' + params.query;
        }
        return this.api.get(url, {
            deskId: params.deskId,
            userId: params.userId
        });
    }

    ongoing(params) {
        let url = this.EP.GET_ONGOING_VISIT;

        return this.api.get(url, {
            deskId: params.deskId,
            userId: params.userId
        });
    }

    selected(params) {
        let url = this.EP.GET_CURRENT_VISITOR_DETAILS;

        return this.api.get(url, {
            deskId: params.deskId,
            userId: params.userId,
            visitorId: params.visitorId,
            visitId: params.visitId
        });
    }


    end(params) {
        if (params.visitId) {
            let url = this.EP.END_ONGOING_VISIT;


            return this.api.put(url, {}, {
                deskId: params.deskId,
                userId: params.userId,
                visitId: params.visitId
            });
        } else {
            let url = this.EP.END_ONGOING_GROUP_VISIT;
            return this.api.put(url, {}, {
                deskId: params.deskId,
                userId: params.userId
            });
        }
    }


    cancel(params) {
        let url = this.EP.CANCEL_VISIT;

        return this.api.put(url, {}, {
            deskId: params.deskId,
            userId: params.userId,
            visitId: params.visitId
        });
    }


    call(params) {
        let url = this.EP.CALL_VISITOR;

        return this.api.put(url, {}, {
            deskId: params.deskId,
            userId: params.userId,
            visitId: params.visitId
        });
    }


    update(data, params) {
        let url = this.EP.UPDATE_VISIT_DETAILS;

        return this.api.put(url, data, {
            deskId: params.deskId,
            userId: params.userId
        });
    }


    add(data, params) {
        let url = this.EP.ADD_VISIT_PERSON;

        return this.api.post(url, data, {
            deskId: params.deskId,
            userId: params.userId
        });
    }
}

export default Desks;