<template>
    <div class="w3-left-align">
        <i class="fa fa-desktop flow-blue-color w3-xlarge w3-margin-right"></i>
        <content-header v-bind:title="$t('addNewDesk')"></content-header>
        <div class="w3-margin-top w3-margin-bottom w3-left-align">
            <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">
                <p class="flow-padding-bottom zero-top w-100pc">
                    <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("name") }}</label>
                    <input class="w3-input w3-border w3-round-medium" type="text"
                           v-model="newDesk.name"
                           v-bind:placeholder="$t('name')"/>
                    <span class="w3-text-red">{{ npNameError }}</span>
                </p>
            </div>


            <div class="w3-container zero-bottom zero-top">
                <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right" @click.prevent="addNewDesk">
                    <i class="fa fa-save flow-green-active-color">
                        <span><b class="flow-margin-left">{{$t('save')}}</b></span>
                    </i>
                </button>

                <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top" @click.prevent="onClose">
                    <i class="fa fa-arrow-left w3-text-red">
                        <span><b class="flow-margin-left">{{$t('back')}}</b></span>
                    </i>
                </button>
            </div>

        </div>
    </div>
</template>

<script>
    import ContentHeader from "../../common/ContentHeader";

    export default {
        name: "NewDesk",
        components: {ContentHeader},
        emits: ['addedNewDesk'],
        props: ['onClose'],
        data() {
            return {
                newDesk: {},
                npNameError: undefined
            }
        },
        methods: {
            addNewDesk() {
                this.npNameError = undefined;
                if (!this.newDesk.name || !this.newDesk.name.trim()) {
                    this.npNameError = this.$t('INVALID_NAME');
                }

                if (this.npNameError) return;
              const app = this;
              app.$restClient.desks.add(this.newDesk).then(response => {
                // eslint-disable-next-line no-unused-vars
                let data = response.data;
                app.$notify.notifySuccess({title: this.$t("success"), message: this.$t('added-success')});
                this.$emit("addedNewDesk", data);
                app.onClose();
              }).catch(err => {
                if(err && err.response.status === 400)
                  app.$notify.notifyError({title: this.$t("failed"), message: this.$t('desk-name-unique')});
                else
                  app.$notify.notifyError({title: this.$t("failed")});
              });

            }
        }
    }
</script>