<template>
  <div>
    <div>
      <span>
        <span :title="$t('personDetails')"
              class="fa fa-stack w3-large cursor-pointer zero w3-ripple w3-margin-right"
              @click="openCard(0)">
          <i class="fa fa-stack-2x fa-circle w3-text-white transform-animation"
             v-bind:class="personDetails ? 'halo-text' : ''"></i>
          <i class="flow-blue2-text fa fa-stack-1x fa-user w3-xlarge"></i>
        </span>

        <span :title="$t('appointments')"
              class="fa fa-stack w3-large cursor-pointer zero w3-ripple w3-margin-right"
              @click="openCard(1)">
          <i class="fa fa-stack-2x fa-circle w3-text-white transform-animation"
             v-bind:class="openAppointments ? 'halo-text' : ''"></i>
          <i class="fa fa-stack-1x fa-clipboard-list w3-xlarge"></i>
        </span>

      </span>
    </div>

    <div v-if="personDetails">
      <div class="flow-blue-color zero-bottom flow-form-title w3-animate-opacity flow-margin-top">
        <EditVisitorForm :selectedVisit="selectedVisit.visitor"
                         :selected-visit-status="selectedVisit.visitStatus"
                         :visitLanguage="selectedVisit.languageName"
                         @refresh-visitor-list="()=>$emit('refresh-visitor-list')"></EditVisitorForm>

      </div>
    </div>
    <div v-if="openAppointments">
      <div class="flow-blue-color zero-bottom flow-form-title w3-animate-opacity">
        <EditAppointmentForm :selectedVisit="selectedVisit"
                             :selected-visit-status="selectedVisit.visitStatus"
                             :callback="callUpdateAppointment"
                             :callback-cancel="callbackCancel"></EditAppointmentForm>

      </div>
    </div>

  </div>
</template>

<script>
import EditVisitorForm from "./EditVisitorForm";
import EditAppointmentForm from "./EditAppointmentForm";

export default {
  name: "VisitorDetails",
  components: {EditVisitorForm, EditAppointmentForm},
  props: ['onClose', 'selectedVisit', 'callbackUpdateAppointment', 'callbackCancel'],

  data() {
    return {
      personDetails: true,
      openAppointments: false,
    }
  },
  watch: {
    selectedVisit: function () {
      this.openCard(0);
    }
  },
  methods: {
    openCard(index) {
      this.personDetails = index === 0;
      this.openAppointments = index === 1;
    },
    callUpdateAppointment(payload) {
      this.callbackUpdateAppointment(payload);
    }
  }
}
</script>

<style scoped>

</style>