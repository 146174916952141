<template>
  <div v-show="!closePicker" class="time-picker" ref="timePicker">
    <div class="col-container">
      <div class="col time-display" v-bind:class="displayCls">
        <div id="time-display-content">
          <div id="time">{{ time.val }}</div>
          <div id="ampm">{{ ampm }}</div>
        </div>
      </div>

      <div class="col hours">
        <div v-for="hrs in hours" class="w3-col hour-row" :key="hrs">
          <div v-for="hr in hrs" class="w3-col hour w3-ripple"
               @click="selectedHr = hr"
               v-bind:class="activeHr === hr ? 'active' : ''"
               :key="hr">
            {{ format(hr) }}
          </div>
        </div>
      </div>

      <div class="col minutes">
        <i class="fa fa-times w3-xlarge w3-display-topright flow-padding cursor-pointer" @click="close"></i>

        <div v-for="mins in minutes" class="w3-col minute-row" :key="mins">
          <div v-for="min in mins" class="w3-col minute w3-ripple"
               @click="selectedMin = min"
               v-bind:class="activeMin === min ? 'active' : ''"
               :key="min">
            {{ format(min) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimePicker",
  props: ['onChange', 'onClose', 'preSelected'],
  created() {
    document.addEventListener("click", this.documentClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.documentClick);
  },
  data() {
    return {
      hours: [
        [0, 1, 2, 3],
        [4, 5, 6, 7],
        [8, 9, 10, 11],
        [12, 13, 14, 15],
        [16, 17, 18, 19],
        [20, 21, 22, 23]
      ],
      minutes: [
        [0, 5, 10, 15],
        [20, 25, 30, 35],
        [40, 45, 50, 55]
      ],
      ampm: '',
      hr: '',
      min: '',
      hrChanged: false,
      displayCls: '',
      activeHr: '',
      activeMin: '',
      closePicker: false
    }
  },
  mounted() {
    var minute = undefined;
    if (this.preSelected) {
      this.selectedHr = this.preSelected.hr;
      minute = this.preSelected.min;
    } else {
      var date = new Date();
      this.selectedHr = date.getHours();
      minute = date.getMinutes();
    }

    this.selectedMin = minute - (minute % 5);
  },
  watch: {
    time(newVal) {
      this.onChange(newVal);
    }
  },
  computed: {
    time() {
      return {hr: this.activeHr, min: this.activeMin, val: this.hr + ":" + this.min};
    },
    selectedHr: {
      set: function (hr) {
        this.activeHr = hr;
        const formatted = this.format(hr);
        this.ampm = hr < 12 ? "AM" : "PM";

        if (formatted !== this.hr) {
          if (hr >= 4 && hr <= 11) {
            this.displayCls = "morning";
          } else if (hr >= 12 && hr <= 15) {
            this.displayCls = "noon";
          } else if (hr >= 16 && hr <= 19) {
            this.displayCls = "evening";
          } else {
            this.displayCls = "night";
          }
        }

        this.hr = formatted;
      },
      get: function () {
        return this.hr;
      }
    },
    selectedMin: {
      set: function (min) {
        this.activeMin = min;
        this.min = this.format(min);
      },
      get: function () {
        return this.min;
      }
    }
  },
  methods: {
    documentClick(e) {
      const tp = this.$refs.timePicker;
      if (!(tp.contains(e.target) || e.target.classList.contains("time-picker-trigger"))) {
        this.close();
      }
    },
    format(val) {
      return (val < 10 ? '0' : '') + val;
    },
    close() {
      this.closePicker = true;
      if (this.onClose) {
        this.onClose();
      }
      document.removeEventListener("click", this.documentClick);
    }
  }
}
</script>