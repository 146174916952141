<template>
  <div>
    <div>
      <div class="w3-container flow-main-content w3-center w3-padding w3-ripple">
      </div>
      <div class="w3-container flow-card blue-top w3-animate-opacity w-70pc flow-desks-content">
        <loading v-bind:dspin="!contentLoaded"></loading>
        <table v-show="contentLoaded" class="w3-table w3-bordered flow-table">
          <thead class="flow-blue-color">
          <th>{{ $t("name") }}</th>
          <th></th>
          </thead>
          <tbody>
          <tr v-show="desks.length === 0">
            <td class="w3-center flow-blue-color" colspan="8">
              <b class="font-size-1pt25em">{{ $t("noDesks") }}</b>
            </td>
          </tr>
          </tbody>

          <tbody class="">
          <div v-for="desk in desks" :key="desk">
            <tr v-bind:id="desk.id" :key="desk.id" class="no-hover w3-border-0">
              <td class="">
                <input v-model="desk.name" class="w3-input w3-border w3-round-medium" type="text"
                       v-bind:placeholder="$t('name')"/>
                <span class="w3-text-red qpp-field-error-message" v-if="!desk.name">{{ $t('INVALID_NAME') }}</span>
              </td>

              <td class="qpp-inline-buttons qpp-desks">
                <span class="btn flow-btn w3-padding-small w3-round-medium"
                      v-bind:class="showSaveButton(desk) ? 'cursor-pointer' : 'w3-disabled'"
                      @click.prevent="saveDesk(desk)">
                  <i class="fa fa-check-circle w3-large flow-green-active-color"></i>
                  <span
                      class="flow-green-active-color flow-margin-left font-weight-bold w3-large">{{
                      $t('confirm')
                    }}</span>
                </span>

                <span>
                  <span
                        class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                        @click="deleteDesk(desk)">
                      <i class="fa fa-trash w3-text-deep-orange"></i>
                      <span
                          class="w3-text-deep-orange flow-margin-left font-weight-bold w3-large">{{
                          $t('delete')
                        }}</span>
                  </span>
              </span>
              </td>
            </tr>

            <tr class="no-hover w3-border-0">
              <td colspan="5">
                <div v-if="desk.clickedDelete">

                  <span class="w3-center w3-col w-100pc w3-margin w3-padding">
                            <span
                                class="w3-large font-weight-bold flow-blue-color flow-margin-right">{{
                                $t('delete')
                              }}
                            <span class="w3-large font-weight-bold w3-text-deep-orange">{{
                                desk.name
                              }}</span> ?
                            </span>
                            <span
                                class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                                @click.prevent="confirmDeletion(desk)">
                                <i class="fa fa-trash w3-text-deep-orange"></i>
                                <span
                                    class="flow-blue-color flow-margin-left font-weight-bold w3-large">{{
                                    $t('yes')
                                  }}</span>
                            </span>

                            <span
                                class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                                @click.prevent="cancelDeletion(desk)">
                                <i class="fa fa-times flow-blue-color"></i>
                                <span
                                    class="flow-blue-color flow-margin-left font-weight-bold w3-large">{{
                                    $t('no')
                                  }}</span>
                            </span>
                        </span>
                </div>
              </td>
            </tr>
          </div>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../common/Loading";

export default {
  name: "DesksList",
  props: ['newDeskAdded'],
  components: {Loading},

  data() {
    return {
      contentLoaded: false,
      desks: [],
      masterCopy: []
    }
  },

  mounted() {
    this.getAllDesks();
  },
  watch: {
    newDeskAdded() {
      this.getAllDesks();
    }
  },

  methods: {

    getAllDesks() {
      const app = this;
      app.desks = [];
      app.$restClient.desks.all().then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.desks = data;
          data.forEach(function (d) {
            app.manageMasterCopy(d);
          });
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    showSaveButton(_desk) {
      if (!_desk || !this.isNameValid(_desk)) {
        return false;
      }
      var mp = this.masterCopy[_desk.id];
      if (!mp) {
        return false;
      }

      return mp.name !== _desk.name;
    },

    saveDesk(desk) {
      if(!this.showSaveButton(desk)) return;

      const app = this;
      app.$restClient.desks.update(desk).then(response => {
        // eslint-disable-next-line no-unused-vars
        let data = response.data;
        app.getAllDesks();
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t('modified-success')});
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      });
    },

    deleteDesk(desk) {
      desk.clickedDelete = true;
    },

    confirmDeletion(desk) {
      const app = this;
      // eslint-disable-next-line no-unused-vars
        app.$restClient.desks.delete(desk.id).then(response => {
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t('modified-success')});

        app.getAllDesks();
      }).catch(err => {
        console.log(err);
        if(err && err.response.status === 422)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('appointment-exist-error')});
        else if(err && err.response.status === 400)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('desk-linked-error')});
        else
          app.$notify.notifyError({title: this.$t("failed")});
      });
    },

    cancelDeletion(desk) {
      desk.clickedDelete = false;
    },

    manageMasterCopy(_desk) {
      this.masterCopy[_desk.id] = {
        name: _desk.name
      };
    },

    isNameValid(_desk) {
      return this.masterCopy.filter(mp => mp.id !== _desk.id && mp.name === _desk.name).length === 0;
    }
  }
}
</script>