class Sites {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_SITES: '/api/sites'
        };
    }

    all() {
        return this.api.get(this.EP.GET_ALL_SITES);
    }

   /* add(data) {
        return this.api.post(
            this.EP.ADD_DESK,
            {
                name: data.name,
                createdBy: 1,
                updatedBy: 1
            }
        );
    }

    update(data) {
        return this.api.put(
            this.EP.GET_UPDATE_DELETE_DESK,
            {
                id: data.id,
                name: data.name
            },
            {
                deskId: data.id,
                createdBy: 1,
                updatedBy: 1
            }
        );
    }

    get(deskId) {
        return this.api.get(
            this.EP.GET_UPDATE_DELETE_DESK,
            {
                deskId: deskId
            }
        );
    }

    delete(deskId) {
        return this.api.delete(
            this.EP.GET_UPDATE_DELETE_DESK,
            {
                deskId: deskId
            }
        );
    } */
}

export default Sites;