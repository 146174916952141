module.exports = {
    "local": {
        "localhost:8081": {
            host: "http://localhost:7000",
            redirectUrl: "http://localhost:8081",
            colorScheme: "#006400" // dark green
        }
    },
    "production": {
        // It has a "." because JS is minifying the keys without the quotes
        "qpp-view.dev.vdab.partheas.net": {
            host: "https://qpp.dev.vdab.partheas.net",
            redirectUrl: "https://qpp-view.dev.vdab.partheas.net",
            colorScheme: "#094e77"
        },
        "saas01.dev.view.qarts.partheas.net": {
            host: "http://saas01.dev.api.qarts.partheas.net",
            redirectUrl: "http://saas01.dev.view.qarts.partheas.net",
            colorScheme: "#bb19f7"
        },
        "saas02.dev.view.qarts.partheas.net": {
            host: "http://saas02.dev.api.qarts.partheas.net",
            redirectUrl: "http://saas02.dev.view.qarts.partheas.net",
            colorScheme: "#000000"
        },
        "saas03.dev.view.qarts.partheas.net": {
            host: "http://saas03.dev.api.qarts.partheas.net",
            redirectUrl: "http://saas03.dev.view.qarts.partheas.net",
            colorScheme: "#f7f719"
        },
        "dev.view.qarts.partheas.net": {
            host: "http://dev.api.qarts.partheas.net",
            redirectUrl: "http://dev.view.qarts.partheas.net",
            colorScheme: "#2b3618"
        }
    }
}