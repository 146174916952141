<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('standard-texts')"></content-header>
        <div class="w3-right w3-margin-right flow-margin-top">
          <i class="fa fa-plus flow-blue-color w3-xlarge cursor-pointer w3-margin-right"
             @click="openNewSideBar"></i>
        </div>
      </div>
    </div>

    <div class="content-sidebars sidebars-70-30 ">
      <div v-if="openNewForm" class="w3-container content-sidebar-right">
        <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max"
           @click="closeAndUpdateSideCard"></i>
        <new-standard-text :languages="languages" :placeholders="placeholders"
                           @updatedStText="closeAndUpdateSideCard"></new-standard-text>
      </div>

      <div v-if="openEditForm" class="w3-container content-sidebar-right">
        <i class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top flow-margin-right-max"
           @click="closeAndUpdateSideCard"></i>

        <edit-standard-text :currentStText="stTextToOpen"
                            :languages="languages"
                            :onClose="closeAndUpdateSideCard"
                            :placeholders="placeholders" @onCloseWindow="closeAndUpdateSideCard"
                            @updatedStText="closeAndUpdateSideCard($event)"></edit-standard-text>

      </div>

      <div v-bind:class="openNewForm || openEditForm? 'content-sidebar-left' : ''">
        <placeholder-list :placeholders="placeholders" ref="expand"></placeholder-list>
        <standard-text-list :stTextListUpdated="updatedTextList"
                            :openNewForm="openNewForm" :openEditForm="openEditForm"
                            @openStTextDetails="openEditSideBar"></standard-text-list>
      </div>
    </div>


  </div>
</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import PlaceholderList from "@/components/views/standard-texts/PlaceholderList";
import StandardTextList from "@/components/views/standard-texts/StandardTextList";
import NewStandardText from "@/components/views/standard-texts/NewStandardText";
import EditStandardText from "@/components/views/standard-texts/EditStandardText";

export default {
  name: "StandardTexts",
  components: {NewStandardText, EditStandardText, ContentHeader, PlaceholderList, StandardTextList},
  data() {
    return {
      openNewForm: false,
      openEditForm: false,
      expandPlaceholders: false,
      updatedTextList: false,
      placeholders: [],
      languages: [],
      stTextToOpen: null
    }
  },
  mounted() {
    this.getAllPlaceholders();
    this.getAllLanguages();
  },

  methods: {

    getAllPlaceholders() {
      const app = this;
      app.placeholders = [];
      app.$restClient.languages.getPlaceholders().then(response => {
        let data = response.data;
        if (data.length > 0) {
          app.placeholders = data;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    getAllLanguages() {
      const app = this;
      app.languages = [];
      app.$restClient.languages.all().then(response => {
        let data = response.data;
        if (data.length > 0) {
          app.languages = data;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    closeAndUpdateSideCard() {
      this.updatedTextList = true;
      this.openNewForm = false;
      this.openEditForm = false;
      this.$refs.expand.close();
    },

    openEditSideBar(_stText) {
      this.stTextToOpen = _stText;
      this.updatedTextList = false;
      this.openEditForm = true;
      this.$refs.expand.open();
    },

    openNewSideBar() {
      this.openNewForm = true;
      this.updatedTextList = false;
      this.$refs.expand.open();
    }
  }
}
</script>