class Visitors {
    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            UPDATE_VISITOR: '/api/sites/:siteId/visitors/:visitorId',
        };
    }

    updateVisitor(visitor){
        let url = this.EP.UPDATE_VISITOR;
        let data = {
            id: visitor.id,
            firstName: visitor.firstName,
            lastName: visitor.lastName
        }
        return this.api.put(url, data, {
            visitorId: visitor.id,
        });
    }
}

export default Visitors;